import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { WallService } from 'app/employee/wall/wall.service';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InappropriateDialogComponent } from 'app/employee/wall/inappropriate-dialog/inappropriate-dialog.component';
import { Router } from '@angular/router';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { CompanyReportService } from 'app/company-report/company-report.service';

@Component({
  selector: 'post-comment-item',
  templateUrl: './post-comment-item.component.html',
  styleUrls: ['./post-comment-item.component.scss']
})
export class PostCommentItemComponent implements OnInit {
  @Output() commentDeleted = new EventEmitter<any>();
  @Output() reportedAsInappropriate = new EventEmitter<any>();

  @Input() itemType = 'Pat';
  @Input() comment;
  @Input() readonly;
  @Input() item: any;
  @Input() showReported: boolean;
  @Input() inappropriatePresent: boolean;
  @Input() isObjComment: boolean;
  @Input() objectiveId: string;

  userInfo: any;
  empInfod: any;
  reportText: any;
  constructor(
    public coreService: CoreService,
    private service: WallService,
    private route: Router,
    private modalService: BsModalService,
    private pp360SpinnerService: Pp360SpinnerService,
    private companyReportService: CompanyReportService
  ) {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngOnInit(): void {
    this.reportText = this.coreService.getTranslation(
      'ReportCommentAsInappropriate'
    );
    if (this.service.profiles.length) {
      const employee = this.service.profiles.find(
        (x) => x.Id === this.comment?.AuthorInfo?.Id
      );
      if (employee) {
        this.empInfod = employee;
      } else {
        this.getProfileInfod();
      }
    } else {
      this.getProfileInfod();
    }
  }
  getProfileInfod() {
    if (this.comment.Author) {
      this.service
        .getEmployeeInfo(this.comment?.Author?.Id)
        .subscribe((data: any) => {
          if (data) {
            this.empInfod = data;
            const employee = this.service.profiles.find(
              (x) => x.Id === this.comment?.Author?.Id
            );
            if (!employee) {
              this.service.profiles.push(data);
            }
          }
        });
    } else {
      this.service
        .getEmployeeInfo(this.comment?.AuthorInfo?.Id)
        .subscribe((data: any) => {
          if (data) {
            this.empInfod = data;
            const employee = this.service.profiles.find(
              (x) => x.Id === this.comment?.AuthorInfo?.Id
            );
            if (!employee) {
              this.service.profiles.push(data);
            }
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this.getProfileInfo(this.comment?.Author);
  }
  getProfileInfo(emp) {
    this.service
      .getEmployeeInfo(this.comment.Author.Id)
      .subscribe((data: any) => {
        if (data) {
          emp.empInfo = data;
          const employee = this.service.profiles.find((x) => x.Id === emp.Id);
          if (!employee) {
            this.service.profiles.push(data);
          }
        }
      });
  }
  editComment(event) {
    this.updateComment(event);
  }
  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true
    });
    // this.bsModalRef.hide();
  }
  replyMesg(event) {
    this.comment.SubComment = event;
    this.replyToComment(event);
  }

  replyToComment(commentItem) {
    const typeIdKey = this.getKeyByType();
    let reqBody = {
      [typeIdKey]: this.item.Id,
      CommentId: this.comment.Id,
      Text: commentItem?.comment,
      Picture: commentItem?.image ? commentItem?.image : null
    };
    if (reqBody.Text) {
      this.pp360SpinnerService.showSpinner(true);
      this.service.commentOnWall(reqBody, this.itemType).subscribe(
        (data: any) => {
          this.comment.SubComments.push(data);
          this.pp360SpinnerService.showSpinner(false);
          this.comment.showReply = false;
          this.item.totalComments++;
        },
        (err: any) => {
          this.pp360SpinnerService.showSpinner(false);
          this.comment.showReply = false;
          console.log('errrr', err);
        }
      );
    }
  }

  getKeyByType() {
    if (this.itemType === 'Pat') {
      return 'TapFeedId';
    } else if (this.itemType === 'Token') {
      return 'TokenId';
    } else if (this.itemType === 'Evaluation') {
      return 'FeedbackId';
    } else if (this.itemType === 'News') {
      return 'NewsId';
    } else if (this.itemType === 'OkrObjective') {
      return 'ObjectiveId';
    } else {
      return null;
    }
  }
  name() {
    return 'comment';
  }

  updateComment(comment) {
    if (comment) {
      // console.log(comment);
      comment.IsEdited = true;
    }
    comment.IsEdited = false;
  }

  reportInnapropriate(item, comment: any = null) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    if (comment) {
      msg = msg.replace('{{item.Author.Name}}', comment.Author.Name);
    } else {
      msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    }

    const content: any = {
      title: msg,
      type: 'Comment'
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation'
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 0
          };

          switch (item.Type) {
            case 'ReceivedAsk':
              req.FeedType = 3;
              break;
            case 'ReceivedEvaluation':
              req.FeedType = 2;
              break;
            case 'ReceivedToken':
              req.FeedType = 1;
              break;
            case 'Pat':
              req.FeedType = 0;
              break;
            default: //COMMENT
              req.FeedType = 4;
              break;
          }

          if (comment) {
            req.FeedType = 4;
            req.FeedId = comment.Id;
            item['commentId'] = comment.Id;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }

  edit(comment: any = null) {
    if (comment) {
      comment.IsEdited = true;
    }
  }

  delete(comment: any = null) {
    const typeIdKey = this.getKeyByType();

    if (comment) {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DELETE'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DELETE_COMMENT'),
        subtitle: this.coreService.getTranslation('WALL.DELETE_COMMENT_TITLE')
      };
      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation'
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            if (typeIdKey === 'ObjectiveId') {
              this.companyReportService
                .deleteCommentInObjective(
                  this.objectiveId,
                  this.comment?.Author?.Id,
                  comment?.Timestamp
                )
                .subscribe(
                  (data: any) => {
                    this.commentDeleted.emit(comment);
                  },
                  (err: any) => {
                    console.log('errrr', err);
                  }
                );
            } else {
              this.service.deleteComment(comment.Id, this.itemType).subscribe(
                (data: any) => {
                  this.commentDeleted.emit(comment);
                },
                (err: any) => {
                  console.log('errrr', err);
                }
              );
            }
          }
        }
      );
    }
  }

  showHideTranslated(comment, property) {
    if (comment.ShowTranslated) {
      comment.ShowTranslated = !comment.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = comment[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          comment['TranslatedText'] = result.TranslatedText;
          comment.ShowTranslated = true;
        });
    }
  }
  getPatImage(item: any) {
    // se l'immagine contiene un base 64 invece di un url
    return item.ImagePath.indexOf('data:image/jpeg;base64,') >= 0
      ? item.ImagePath
      : this.coreService.getImageURL(item.ImagePath, null, item.IsReported);
  }
}
