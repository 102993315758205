import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef
} from '@angular/core';
//RxJS
import { Subject, BehaviorSubject } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';

import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
//import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IosAutocompleteComponent } from '../ios-autocomplete/ios-autocomplete.component';

import { ImageSelectorComponent } from 'app/company-report/objectives-individual/objective/obj-sheet-popup/image-selector/image-selector.component';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

// Cordova
import { Platform } from '@ionic/angular';
import { CustomImageCropperComponent } from '../custom-image-cropper/custom-image-cropper.component';
import { base64ToFile } from 'ngx-image-cropper';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'modal-content',
  templateUrl: './new-pat.component.html',
  styleUrls: [
    './new-pat.component.scss',
    '../../../common_styles.scss',
    '../common_popup_styles.scss'
  ],
  providers: [
    Keyboard,
    {
      provide: BsDropdownConfig,
      useValue: { autoClose: true }
    }
  ]
})
export class NewPatComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onClose: Subject<any>;
  pat: any;
  public refreshWallRequired: Subject<any>;

  uploadForm: FormGroup;
  public selectControl = new FormControl();
  companyId: any;
  selectedBehaviors = [];
  selectedSkills = [];
  selectedGoals = [];
  selectedItem = [];
  allFilteredDataList: any[] = [];
  selectedSkill: any = '';
  selectedEmployeeList = [];
  isSelected: boolean = false;
  all_Employees_Teams = [];
  commentEdited = false;
  ischangeImg: boolean = false;

  refSkillLibrary: any;
  selectedCategory = [];
  user_Info: any;
  currentEmployeeId: any;
  companySkills: any;
  companyGoals: any;
  companyBehaviors: any;

  selectedTeams_Employees = [];
  isPreselected: boolean = false;
  // isProcessing:boolean = true;
  selectTeamsEmployeesJSON = {};
  targetEmpTeam: any;
  targetEmployee: any;
  optionalPhotoData: any;

  preSelectedEmployee: boolean = false;
  isKeyResult: boolean = false;
  showMandatory: boolean = false;
  newPatData: any = {};

  selectedActivity: any = [];
  companyActivities: any;
  globalCompanyActivities: any;
  isActivityProcessing: boolean = true;

  settingsTeamEmployee = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };
  device = '';
  os = '';
  os_version = '';

  pageStart = 1;
  pageSize = 50;
  currentList = [];

  isMobile = false;
  searchText: string;
  serachTextChanged: Subject<string> = new Subject<string>();

  deviceInfo = null;
  isSubmitPat: boolean = false;
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() getResponse = new EventEmitter();

  almostOneTeam: boolean = false;
  isUploading: boolean = false;

  iOS: boolean =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  @Input() public patForm: any;

  selectedItemIOS: any[] = [];
  iosModalRef: any;
  croppedImage: any = '';
  disableEmployeesSelect = false;
  resetTime: boolean = true;
  reloadAct: boolean = true;
  currentFile: any;
  finalCroppedImage: any;
  finalName: any;
  isEditableImg: boolean = false;
  showLoader: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef,
    public coreService: CoreService,
    private modalService: BsModalService,
    public sharedService: SharedService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    public platform: Platform,
    public screenSizeService: ScreenSizeService,
    private keyboard: Keyboard,
    private ref: ChangeDetectorRef,
    private pp360SpinnerService: Pp360SpinnerService // ,private deviceService: DeviceDetectorService
  ) {
    this.selectControl.valueChanges.subscribe((value) => console.log(value));

    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  /*------------------------------------------------------------------------------------------------------------ */
  ngOnInit() {
    this.pat = {
      patType: '',
      Skill: undefined,
      Goal: undefined,
      Activity: undefined
    };
    this.user_Info = this.coreService.getUserInfo();
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    this.onClose = new Subject();
    this.refreshWallRequired = new Subject();
  }

  refreshRadioSelection() {
    if (this.user_Info.hasSkills) {
      this.pat.patType = 'skill';
      this.isKeyResult = false;
    } else if (this.user_Info.hasObjectives) {
      this.pat.patType = 'goal';
      this.isKeyResult = false;
      this.companyActivities = [];
      this.selectedActivity = [];
      this.pat.Activity.Id = undefined;
    } else if (this.user_Info.hasBehaviors) {
      this.pat.patType = 'behavior';
      this.isKeyResult = false;
    }
  }

  /*------------------------------------------------------------------------------------------------------------ */
  public async showModal(content: any) {
    this.isSubmitPat = true;
    this.pat = JSON.parse(JSON.stringify(content.pat));
    if (this.pat && this.pat.ImagePath) {
      const urlSegments = this.pat.ImagePath.split('/');
      const lastSegment = urlSegments[urlSegments.length - 1];
      const imageName = lastSegment.split('?')[0];
      this.finalName = imageName;
    }
    this.all_Employees_Teams = content.all_Employees_Teams;

    this.selectedItemIOS = [];
    const preselected = this.all_Employees_Teams.filter((i) => i.isPreselect);
    preselected.forEach((item) => {
      this.targetEmpTeam = item;
      this.isPreselected = true;
      this.selectedItemIOS.push(item);
    });
    // console.log(this.isPreselected);
    await this.preSelectedSkills(preselected);
    await this.preSelectedActivities(preselected);
    await this.preSelectedGoals(preselected);
    await this.preSelectedBehaviors(preselected);

    // imposto la skill, se è di tipo skill
    this.selectedSkill =
      this.pat.patType == 'skill'
        ? this.companySkills.find((s) => s.Id == this.pat.Skill?.Id)?.Name
        : undefined;

    // imposto il goal, se è di tipo goal
    this.selectedGoals =
      this.pat.patType == 'goal'
        ? this.companyGoals.find((s) => s.Id == this.pat.Goal?.Id)?.Name
        : undefined;

    // imposto la behavior, se è di tipo behavior
    this.selectedBehaviors =
      this.pat.patType == 'behavior' && this.pat.PostSubject == 1
        ? this.companyBehaviors.find((s) => s.Id == this.pat.Skill?.Id)?.Name
        : undefined;

    this.selectedActivity = this.pat?.Activity?.Name;

    this.isSubmitPat = false;
  }

  ngAfterViewInit() {
    this.refreshRadioSelection();
  }

  /*------------------------------------------------------------------------------------------------------------ */
  isDisabled() {
    var disabled =
      !this.all_Employees_Teams ||
      (this.all_Employees_Teams && !this.all_Employees_Teams.length);
    if (!disabled) {
      if (this.pat.patType === 'goal') {
        //Goall
        disabled = !this.selectedGoals;
        if (!disabled && !this.iOS) {
          disabled = !this.selectedGoals.length;
        }
      } else if (this.pat.patType === 'skill') {
        //Skill
        // disabled = !this.selectedSkills;
        if (!disabled && !this.iOS) {
          // disabled = !this.selectedSkills.length;
        }
      } else {
        //behavior
        disabled = !this.selectedBehaviors;
        if (!disabled && !this.iOS) {
          disabled = !this.selectedBehaviors.length;
        }
      }
    }
    return disabled;
  }

  /*------------------------------------------------------------------------------------------------------------ */
  async preSelectedSkills(preselected: any) {
    let teamId = [];
    let employeeId = [];

    preselected.forEach((item) => {
      item.Type === 'Team'
        ? teamId.push(item.Id.substring(2))
        : employeeId.push(item.Id.substring(2));
      if (item.Type == 'GivenEvaluation' || item.Type == 'GivenToken') {
        employeeId.push(item.Target.Id);
        teamId = [];
      }
      if (item.Type == 'ReceivedEvaluation' || item.Type == 'ReceivedToken') {
        employeeId.push(item.Author.Id);
        teamId = [];
      }
    });

    if (employeeId.length > 0 || teamId.length > 0) {
      return await this.getSkillId(employeeId, teamId);
    } else {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getSkillId(employeeId, teamId) {
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: employeeId,
      Teams: teamId
    };
    await this.getSkills(reqBody);
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getSkills(reqBody) {
    this.showLoader = true;
    const data = await this.sharedService.getSkills(reqBody).toPromise();
    // console.log('getSkills', data);
    this.companySkills = data;
    this.companySkills.sort((a: any, b: any) => {
      return a.Name.toLowerCase() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    this.showLoader = false;
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async preSelectedBehaviors(preselected: any) {
    let teamId = [];
    let employeeId = [];
    preselected.forEach((el) => {
      el.Type === 'Team'
        ? teamId.push(el.Id.substring(2))
        : employeeId.push(el.Id.substring(2));
      if (el.Type == 'GivenEvaluation' || el.Type == 'GivenToken') {
        employeeId.push(el.Target.Id);
        teamId = [];
      }
      if (el.Type == 'ReceivedEvaluation' || el.Type == 'ReceivedToken') {
        employeeId.push(el.Author.Id);
        teamId = [];
      }
    });
    if (employeeId.length > 0 || teamId.length > 0) {
      return await this.getBehaviorId(employeeId, teamId);
    } else {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getBehaviorId(employeeId, teamId) {
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: employeeId,
      Teams: teamId
    };
    await this.getBehaviors(reqBody);
  }

  async getBehaviors(reqBody) {
    this.showLoader = true;
    const data = await this.sharedService.getBehaviors(reqBody).toPromise();
    this.companyBehaviors = data;
    this.companyBehaviors.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    this.showLoader = false;
  }
  /*------------------------------------------------------------------------------------------------------------ */

  async preSelectedGoals(preselected: any) {
    let teamId = [];
    let employeeId = [];
    preselected.forEach((el) => {
      el.Type === 'Team'
        ? teamId.push(el.Id.substring(2))
        : employeeId.push(el.Id.substring(2));
      if (el.Type == 'GivenEvaluation' || el.Type == 'GivenToken') {
        employeeId.push(el.Target.Id);
        teamId = [];
      }
      if (el.Type == 'ReceivedEvaluation' || el.Type == 'ReceivedToken') {
        employeeId.push(el.Author.Id);
        teamId = [];
      }
    });
    if (employeeId.length > 0 || teamId.length > 0) {
      return await this.getGoalId(employeeId, teamId);
    } else {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getGoalId(employeeId, teamId) {
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: employeeId,
      Teams: teamId
    };
    await this.getGoals(reqBody);
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getGoals(reqBody) {
    this.showLoader = true;
    const data = await this.sharedService.getGoals(reqBody).toPromise();
    // console.log('getGoals', data);
    this.companyGoals = data;
    this.companyGoals.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    this.showLoader = false;
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async preSelectedActivities(preselected: any) {
    let teamId = [];
    let employeeId = [];
    preselected.forEach((el) => {
      el.Type === 'Team'
        ? teamId.push(el.Id.substring(2))
        : employeeId.push(el.Id.substring(2));
      if (el.Type == 'GivenEvaluation' || el.Type == 'GivenToken') {
        employeeId.push(el.Target.Id);
        teamId = [];
      }
      if (el.Type == 'ReceivedEvaluation' || el.Type == 'ReceivedToken') {
        employeeId.push(el.Author.Id);
        teamId = [];
      }
    });
    if (employeeId.length > 0 || teamId.length > 0) {
      return await this.getActivityId(employeeId, teamId);
    } else {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getActivityId(employeeId, teamId) {
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: employeeId,
      Teams: teamId
    };
    await this.getActivities(reqBody);
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async getActivities(reqBody) {
    const data = await this.sharedService
      .getCompanyActivities(reqBody)
      .toPromise();
    this.isActivityProcessing = false;
    this.companyActivities = data;
    this.companyActivities.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    this.globalCompanyActivities = data;
    this.globalCompanyActivities.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }

  /*------------------------------------------------------------------------------------------------------------ */
  onSelectTeamEmployeeRemove() {
    this.companySkills = [];
    this.companyGoals = [];
    this.selectedSkills = [];
    this.selectedBehaviors = [];
    this.selectedGoals = [];
    this.selectedItem = [];
    this.selectedActivity = [];
    this.showLoader = false;
  }
  /*------------------------------------------------------------------------------------------------------------ */
  async onSelectTeamEmployeeChange(event) {
    let teamId = [];
    let employeeId = [];
    this.currentEmployeeId = this.user_Info.linkedEmployeeId;
    this.selectedItem = event;
    if (this.selectedTeams_Employees.length > 0) {
      this.all_Employees_Teams.forEach((el) => {
        this.selectedItem.forEach((item: any) => {
          var id = item.substring(2);
          var t = item.substring(0, 1);
          if (el.Id == item) {
            t == 'e' ? employeeId.push(id) : teamId.push(id);
          }
          if (t !== 'e') {
            this.almostOneTeam = true;
          }
        });
      });
      await this.getSkillId(employeeId, teamId);
      await this.getGoalId(employeeId, teamId);
      await this.getBehaviorId(employeeId, teamId);
      await this.getActivityId(employeeId, teamId);
    } else {
      this.selectedTeams_Employees = [];
    }
  }

  public async preselectEmployee(e) {
    this.selectedItemIOS = e;
    this.all_Employees_Teams = e;
    this.selectedTeams_Employees = e;
    const preselected = this.all_Employees_Teams.filter((i) => i.isPreselect);
    this.disableEmployeesSelect = !!preselected;
    this.onSelectTeamEmployeeChange([e[0].Id]);
  }

  cropImage(file) {
    const cropModalRef = this.modalService.show(CustomImageCropperComponent, {
      class: 'image-change'
    });
    const content = {
      file,
      isRounded: false,
      aspectRatio: 4 / 2
    };
    (<CustomImageCropperComponent>cropModalRef.content).showModal(content);
    (<CustomImageCropperComponent>cropModalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.croppedImage = result;
          this.uploadImage(file);
          this.isDone();
        }
      }
    );
  }
  getImageSize(file) {
    return file ? (file.size / (1024 * 1024)).toFixed(2) : 0;
  }
  isDone() {
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(base64ToFile(this.croppedImage))
    );
    this.finalCroppedImage = url;
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  /*------------------------------------------------------------------------------------------------------------ */
  onAddImage(event) {
    if (this.newPatData) {
      this.ischangeImg = true;
    }
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.isUploading = true;
      let file: File = fileList[0];
      this.currentFile = file;
      this.finalName = this.currentFile.name;
      this.cropImage(file);
    }
  }

  uploadImage(file) {
    const imageFile = base64ToFile(this.croppedImage);
    let formData: FormData = new FormData();
    formData.append('uploadFile', imageFile, file.name);
    this.pp360SpinnerService.showSpinner(true);
    this.sharedService.onAddImage(formData).subscribe(
      (data: any) => {
        this.optionalPhotoData = data;
        this.pat.ImagePath =
          this.coreService.getBaseURL() + this.optionalPhotoData.ImageUrl;
        this.pp360SpinnerService.showSpinner(false);
        this.isUploading = false;
      },
      (err: any) => {
        console.log('onAddImage err => ', JSON.stringify(err));
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isUploading = false;
      }
    );
  }
  /*------------------------------------------------------------------------------------------------------------ */
  cancel() {
    if (this.all_Employees_Teams) {
      this.all_Employees_Teams.forEach((e) => {
        e['selected'] = false;
      });
    }
    this.bsModalRef.hide();
  }

  /*------------------------------------------------------------------------------------------------------------ */
  sendingConfirmation: boolean = false;
  onConfirm() {
    // console.log(this.disableSend());
    if (this.disableSend() || this.sendingConfirmation) return;
    this.sendingConfirmation = true;
    this.isSubmitPat = true;
    let employeeId = [];
    let teamId = [];
    let skillId = this.pat.Skill?.Id;
    let goalId = this.pat.Goal?.Id;
    let activityId = this.pat.Activity?.Id;
    let behaviorId =
      this.pat.Skill &&
      (this.pat.PostSubject == 1 || this.pat.patType == 'behavior')
        ? this.pat.Skill?.Id
        : undefined;

    if (this.selectedItemIOS && this.selectedItemIOS.length > 0) {
      this.selectedItemIOS.forEach((item: any) => {
        if (item.Type === 'Employee') {
          employeeId.push({ Id: item.Id.replace('e:', '') });
        } else {
          teamId.push({ Id: item.Id.replace('t:', '') });
        }
      });
    } else {
      if (this.targetEmployee == undefined) {
        this.all_Employees_Teams.forEach((el) => {
          if (el.Id == this.targetEmpTeam.Id) {
            let type = el.Type;
            type === 'Employee'
              ? employeeId.push({ Id: this.targetEmpTeam.Id })
              : teamId.push({ Id: this.targetEmpTeam.Id });
          }
        });
      } else {
        if (
          this.targetEmployee.Type == 'GivenEvaluation' ||
          this.targetEmployee.Type == 'GivenToken'
        ) {
          let type = this.targetEmployee.Target.JobTitle;
          employeeId.push({ Id: this.targetEmployee.Target.Id });
        }
        if (
          this.targetEmployee.Type == 'ReceivedEvaluation' ||
          this.targetEmployee.Type == 'ReceivedToken'
        ) {
          employeeId.push({ Id: this.targetEmployee.Author.Id });
        }
      }
    }
    this.newPatData = {
      Author: {
        Id: this.user_Info.linkedEmployeeId
      },
      Target: {
        Employees: employeeId,
        Teams: teamId
      },
      ImagePath: this.pat.ImagePath,
      Description: this.pat.Description?.replaceAll('<br>', '')
        .replaceAll('<br/>', '')
        .replace(/\r?\n|\r/, '')
    };

    if (this.pat.Id) {
      this.newPatData.Id = this.pat.Id;
    }

    if (activityId && activityId.length > 0) {
      this.newPatData.Activity = {
        Id: activityId
      };
    }

    if (this.pat.patType === 'skill') {
      this.newPatData.Skill = {
        Id: skillId
      };
    } else if (this.pat.patType === 'goal') {
      var foundGoal = this.companyGoals.find((x) => x.Id == goalId);
      this.newPatData.Goal = {
        Id: goalId,
        IsFromCycle: foundGoal.IsFromCycle
      };
    } else if (this.pat.patType === 'behavior') {
      this.newPatData.Skill = {
        Id: behaviorId
      };
    }

    this.refreshWallRequired.next(this.newPatData);
    this.onClose.next(this.newPatData);

    if (this.newPatData.Id) {
      this.pp360SpinnerService.showSpinner(true);
      this.sharedService.updatePatFeed(this.newPatData).subscribe(
        (data: any) => {
          this.update.emit(data);
          // this.coreService.toasterMessage(
          //   "success",
          //   "company",
          //   this.coreService.getTranslation("Notifications_MSG.Updated")
          // );
          this.coreService.setShowPublished({ type: 'Pat', isEdit: true });

          this.isSubmitPat = false;
          if (this.all_Employees_Teams) {
            this.all_Employees_Teams.forEach((e) => {
              e['selected'] = false;
            });
          }
          // this.refreshWallRequired.next(this.newPatData);
          this.sendingConfirmation = false;
          this.pp360SpinnerService.showSpinner(false);
        },

        (err: any) => {
          this.pp360SpinnerService.showSpinner(false);
          this.coreService.toasterMessage('error', 'wall', err.Message);
          this.sendingConfirmation = false;
        }
      );
    } else {
      this.sharedService.newPatFeed(this.newPatData).subscribe(
        (data: any) => {
          this.add.emit(data);
          // this.coreService.toasterMessage(
          //   "success",
          //   "company",
          //   this.coreService.getTranslation("Notifications_MSG.Sent")
          // );
          this.coreService.setShowPublished({ type: 'Pat', isEdit: false });

          this.isSubmitPat = false;
          if (this.all_Employees_Teams) {
            this.all_Employees_Teams.forEach((e) => {
              e['selected'] = false;
            });
          }
          // this.refreshWallRequired.next(this.newPatData);
          this.sendingConfirmation = false;
        },

        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
          this.sendingConfirmation = false;
        }
      );
    }

    this.bsModalRef.hide();
  }
  /*------------------------------------------------------------------------------------------------------------ */
  checkDisabled() {
    const isGoalEmpty =
      this.pat.patType === 'goal' &&
      (!this.selectedGoals ||
        (this.selectedGoals && !this.selectedGoals.length));
    const isSkillEmpty =
      this.pat.patType === 'skill' &&
      (!this.selectedSkills ||
        (this.selectedSkills && !this.selectedSkills.length));
    const isBehaviorEmpty =
      this.pat.patType === 'behavior' &&
      (!this.selectedBehaviors ||
        (this.selectedBehaviors && !this.selectedBehaviors.length));
    const inAllEmployeesTeamsEmpty =
      !this.all_Employees_Teams ||
      (this.all_Employees_Teams && !this.all_Employees_Teams.length);

    return (
      !this.patForm.valid ||
      this.isSubmitPat ||
      isGoalEmpty ||
      isSkillEmpty ||
      isBehaviorEmpty ||
      inAllEmployeesTeamsEmpty
    );
  }
  /*------------------------------------------------------------------------------------------------------------ */
  iosAutocompleteDialog() {
    if (this.isPreselected) return;
    this.iosModalRef = this.modalService.show(IosAutocompleteComponent, {
      class: 'iosAutocompleteDialog',
      ignoreBackdropClick: true
    });
    this.iosModalRef.content.setData(this.all_Employees_Teams);
    this.iosModalRef.content.selectionMultiple = true;
    this.iosModalRef.content.selectionViewType = 'EMPLOYEES_TEAMS';
    this.iosModalRef.content.title = this.translate.instant(
      'Generic.ChooseEmployee'
    );
    this.iosModalRef.content.getResponse.subscribe((data: any) => {
      this.selectedItemIOS = data.selected;
      this.all_Employees_Teams = data.originalList;
      this.selectedTeams_Employees = data.selected.map((item: any) => item.Id);
      this.onSelectTeamEmployeeChange(
        data.selected.map((item: any) => item.Id)
      );
    });
  }
  /*------------------------------------------------------------------------------------------------------------ */
  disableSend() {
    return (
      this.isSubmitPat ||
      !this.selectedItemIOS ||
      this.selectedItemIOS.length == 0 ||
      (this.pat.patType === 'skill' &&
        (!this.pat.Skill || !this.pat.Skill.Id)) ||
      (this.pat.patType === 'behavior' &&
        (!this.pat.Skill || !this.pat.Skill.Id)) ||
      (this.pat.patType === 'goal' && (!this.pat.Goal || !this.pat.Goal.Id))
    );
  }
  /*------------------------------------------------------------------------------------------------------------ */
  // selectedSkillId: string;
  typeaheadOnBlur(event: TypeaheadMatch): void {
    // console.log(event);
  }
  /*------------------------------------------------------------------------------------------------------------ */
  // selectedBehaviorId: string;
  onSelectBehavior(event: any) {
    if (!this.pat.Skill) this.pat.Skill = {};
    this.pat.Skill.Id = event.Id;
  }
  /*------------------------------------------------------------------------------------------------------------ */
  // selectedObjectiveId: string;
  onSelectObjective(event: any) {
    if (!this.pat.Goal) this.pat.Goal = {};
    this.pat.Goal.Id = event.Id;
    this.onGoalChange(event.Id);
  }
  /*------------------------------------------------------------------------------------------------------------ */
  onGoalChange(e) {
    var foundGoal = this.companyGoals.find((x) => x.Id == e);
    if (foundGoal && foundGoal.IsFromCycle) {
      this.isKeyResult = true;
      this.companyActivities = [];
      if (foundGoal.KeyResults) {
        foundGoal.KeyResults.forEach((element: any) => {
          this.companyActivities.push({
            Id: element.Item1,
            Name: element.Item2
          });
          this.companyActivities.sort((a: any, b: any) => {
            return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
              ? -1
              : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
                ? 1
                : 0;
          });
        });
      }
    } else {
      this.companyActivities = this.globalCompanyActivities;
      this.isKeyResult = false;
    }
  }
  /*------------------------------------------------------------------------------------------------------------ */
  // selectedActivityId: string;
  onSelectActivity(event: any) {
    if (!this.pat.Activity) this.pat.Activity = {};
    this.pat.Activity.Id = event?.Id;
  }
  /*------------------------------------------------------------------------------------------------------------ */
  getPatImage() {
    if (this.pat.ImagePath) {
      return {
        'background-image': `url('${this.pat.ImagePath}')`,
        'background-color': `var(--feed-background)`,
        border: `none`
      };
    } else {
      return undefined;
    }
  }
  /*------------------------------------------------------------------------------------------------------------ */
  selectImage() {
    const content: any = {
      Search: ''
    };
    let modal = this.modalService.show(ImageSelectorComponent, {
      class: 'image-selector'
    });
    (<ImageSelectorComponent>modal.content).showModal(content);

    if (this.isMobile) {
      setTimeout(() => {
        $('.modal-backdrop').attr('style', 'z-index: 3002 !important');
        $('modal-container:last-child').css('z-index', '3003');
      }, 1000);
    }
    if (this.newPatData) {
      this.ischangeImg = true;
    }
    (<ImageSelectorComponent>modal.content).onCloseModal.subscribe(
      (result: string) => {
        // console.log('onCloseModal', result);
        if (result) {
          // console.log('onCloseModal', result);
          this.loading$.next(true);
          this.pat.ImagePath = result;

          setTimeout(() => {
            // Immagine fittizzia per sapere quando viene caricata
            var image = new Image();
            image.src = result;
            image.onload = () => {
              this.loading$.next(false);
            };
          }, 250);
        }
      }
    );

    (<ImageSelectorComponent>modal.content).onClose.subscribe((result: any) => {
      if (result) {
        this.finalCroppedImage = result.urls.small;
        // this.currentFile.size = 0;
        this.finalName = result.user.name;

        this.pat.ImagePath = result.urls.small;
        // console.log('ImagePath', this.pat.ImagePath);
        this.onEdit(result.urls.regular);

        this.loading$.next(true);
        setTimeout(() => {
          // Immagine fittizzia per sapere quando viene caricata
          var image = new Image();
          image.src = this.pat.ImagePath;
          image.onload = () => {
            this.loading$.next(false);
          };
        }, 250);
      }
    });

    modal.onHidden.subscribe(() => {
      $('.modal-backdrop').attr('style', 'z-index: 2040 !important');
    });
  }

  onSelectedEmployee(data: any) {
    this.selectedItemIOS = data.selected;
    this.all_Employees_Teams = data.originalList;
    this.selectedTeams_Employees = data.selected.map((item: any) => item.Id);
    this.onSelectTeamEmployeeChange(data.selected.map((item: any) => item.Id));
  }
  onRemoveImg() {
    this.pat.ImagePath = '';
    this.isEditableImg = false;
    this.ischangeImg = true;
    const fileInput = document.getElementById('addImage') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
  selectSkillItem(data: any) {
    if (data) {
      this.selectedSkill = data.Id[0];
      if (!this.pat.Skill) this.pat.Skill = {};
      this.pat.Skill.Id = data.Id;
      this.selectedSkill = data.Name;
      this.keyboard.hide();
      console.log(
        this.selectedSkill,
        '@@',
        this.pat.Skill.Id,
        '@@',
        this.selectedSkill
      );
    } else {
      this.pat.Skill.Id = null;
      this.pat.Skill = {};
    }
  }
  cancelChanges() {
    if (
      (this.selectedItemIOS?.length == 0 && !this.ischangeImg) ||
      (this.isPreselected && !this.commentEdited && !this.ischangeImg)
    ) {
      this.bsModalRef.hide();
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DISCARD'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DISCARD_CHANGES'),
        subtitle: this.coreService.getTranslation('WALL.DISCARD_CHANGES_TITLE')
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation'
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.bsModalRef.hide();
          }
        }
      );
    }
  }
  reloadActivity() {
    this.reloadAct = false;
    this.pat.Description = '';
    setTimeout(() => {
      this.reloadAct = true;
    }, 10);
  }

  async onEdit(item: any) {
    const response = await fetch(item);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    this.croppedImage = { file, url };
    this.cropImage(file);
  }
  onImageShow() {
    setTimeout(() => {
      this.isEditableImg = false;
    }, 1000);
  }

  makeImgEditable() {
    if (this.isMobile) {
      this.isEditableImg = true;
    }
  }
}
