import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pp360-dropdown',
  templateUrl: './pp360-dropdown.component.html',
  styleUrls: ['./pp360-dropdown.component.scss']
})
export class Pp360DropdownComponent implements OnInit {
  settings: any;
  selectedItems: any;
  modelChange: Subject<any> = new Subject<any>();
  loading: boolean;

  // NgModel
  @Input() dropModel: any;
  @Output() dropModelChange = new EventEmitter<any>();

  // Inputs
  @Input() data: any;
  @Input() multi: boolean = false;
  @Input() image: boolean = false;
  @Input() showCheckbox: boolean = false;
  @Input() searchFilter: boolean = false;
  @Input() optionFullWidth: boolean = false;
  @Input() imageField: string;
  @Input() descriptionField: string;
  @Input() placeholder: string;
  @Input() selectAllText: string;
  @Input() unSelectAllText: string;
  @Input() filterSelectAllText: string;
  @Input() filterUnSelectAllText: string;
  @Input() searchPlaceholderText: string;
  @Input() noDataLabel: string;
  @Input() enableCheckAll: boolean = false;
  @Input() primaryKey: string;
  @Input() labelKey: string;

  // Outputs
  @Output() onInputChange = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onOpen = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onDeSelect = new EventEmitter<any>();
  @Output() onScrollToEnd = new EventEmitter<any>();
  @Output() onModelChange = new EventEmitter<any>();

  constructor(private translate: TranslateService) {
    this.modelChange
      .pipe(
        debounceTime(500), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe((filter: string) => {
        this.onInputChange.emit(filter);
      });
  }

  ngOnInit(): void {
    const optionFullWidth = this.optionFullWidth ? 'optionFullWidth' : '';
    this.data?.forEach((element) => {
      element.Values?.forEach((x) => {
        if (x.Name === '') {
          x.Name = this.translate.instant(
            'COMPANY_PERFORMANCE_REVIEW.FILTER_COLUMN.NONE'
          );
        }
      });
    });
    const settings = {
      text: this.placeholder,
      selectAllText: this.selectAllText ?? 'selectAllText',
      unSelectAllText: this.unSelectAllText ?? 'unSelectAllText',
      filterSelectAllText:
        this.filterSelectAllText ??
        this.translate.instant('Generic.filterSelectAllText'),
      filterUnSelectAllText:
        this.filterUnSelectAllText ??
        this.translate.instant('Generic.filterUnSelectAllText'),
      searchPlaceholderText:
        this.searchPlaceholderText ?? this.translate.instant('Generic.Search'),
      noDataLabel:
        this.noDataLabel ?? this.translate.instant('Generic.NoDataAvailable'),
      classes: `pp360Dropdown ${optionFullWidth}`,
      showCheckbox: this.showCheckbox,
      singleSelection: !this.multi,
      enableCheckAll: this.enableCheckAll,
      enableSearchFilter: this.searchFilter,
      primaryKey: this.primaryKey ? this.primaryKey : null,
      labelKey: this.labelKey ? this.labelKey : null
    };

    if (!this.primaryKey) {
      delete settings.primaryKey;
    }
    if (!this.labelKey) {
      delete settings.labelKey;
    }

    this.settings = settings;

    this.selectedItems = this.dropModel;
  }

  onSearch(event) {
    const filter = event.target.value;
    this.modelChange.next(filter);
  }

  onCloseEvent(event: any) {
    this.onClose.emit(event);
  }

  onOpenEvent(event: any) {
    this.onOpen.emit(event);
  }

  onSelectEvent(event: any) {
    // console.log(event);

    this.onSelect.emit(event);
    this.selectedItems = this.selectedItems.sort((a, b) => {
      if (a.Name) {
        return a.Name.trim() < b.Name.trim()
          ? -1
          : a.Name.trim() > b.Name.trim()
            ? 1
            : 0;
      } else if (a.itemName) {
        return a.itemName.trim() < b.itemName.trim()
          ? -1
          : a.itemName.trim() > b.itemName.trim()
            ? 1
            : 0;
      }
    });
    this.dropModelChange.emit(this.selectedItems);
    this.onModelChange.emit(this.selectedItems);
  }

  onDeSelectEvent(event: any) {
    this.onDeSelect.emit(event);
    this.dropModelChange.emit(this.selectedItems);
    this.onModelChange.emit(this.selectedItems);
  }

  onScrollToEndEvent(event: any) {
    this.onScrollToEnd.emit(event);
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change.dropModel &&
      (change.dropModel.currentValue !== change.dropModel.previousValue ||
        change.dropModel.currentValue === '' ||
        change.dropModel.currentValue === undefined)
    ) {
      // è come un inizializzazione, quando lo metto a undefined lancia il trigger
      setTimeout(() => {
        this.selectedItems = change.dropModel.currentValue;
        //this.dropModel = change.dropModel.currentValue;
      }, 100);
    }
  }
  onDeSelectAll(event: any) {
    this.onDeSelect.emit(event);
    this.dropModelChange.emit(this.selectedItems);
    this.onModelChange.emit(this.selectedItems);
  }
}
